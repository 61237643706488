import type { Channel } from "@skydio/channels";
import type { RecvExtras, SendExtras, SkybusTunnel, Transport } from "@skydio/skybus-experimental";
import type { SliceCreator } from "../zustandTypes";

interface ChannelIndexEntry<TMessage> {
  channel: Channel<TMessage>;
  refCount: number;
}

interface DeviceChannelStore {
  latest: { [key: string]: any }; // TODO(trey + david-tsai-skydio): how can any be T from Channel<T> based on key?
  // NOTE(trey): we wouldn't need this index if there was gencode for a full channel index; right now,
  // this is required because we can't look up the deserializer for a channel by name in TS like we
  // can in python, so we rely on clients to provide it when subscribing to a channel
  index: { [key: string]: ChannelIndexEntry<any> }; // separate from subscription to avoid triggering updated when adding new subscribers to an existing subscription
}

export class NoopTransport implements Transport<SendExtras, RecvExtras> {
  id() {
    return "no-op-transport";
  }

  send(dst: string, payload: Uint8Array, extras?: SendExtras): void {
    return;
  }

  addMessageListener(): () => void {
    return () => undefined;
  }
}

export interface DeviceChannelsSlice {
  /**
   * ********** TODO(david-tsai-skydio) *********
   * The following methods should be considered private
   */

  /** Shimmed Transport  */
  transport: Transport<SendExtras, RecvExtras>;
  /** Skybus tunnels */
  tunnels: { [key: string]: SkybusTunnel };
  /** set the transport for tunnels to use */
  setTransport(transport: Transport<SendExtras, RecvExtras>): void;
  /** clear the transport for tunnels to use */
  clearTransport(): void;
  /** set the latest value on a channel from a specific device */
  setChannelLatest(deviceId: string, channel: string, payload: Uint8Array): void;

  /**
   * Adds a tunnel to the store.
   * @returns a function to remove the tunnel
   */
  addTunnel(...tunnels: Array<SkybusTunnel>): () => void;

  /**
   * ********** TODO(david-tsai-skydio) *********
   * The following methods are considered public
   */

  /** Channels feature device channel data by device id. */
  channels: { [key: string]: DeviceChannelStore };
  /** Subscribe to a channel from device, returns an unsubscribe function. */
  subscribe<TMessage>(deviceId: string, channel: Channel<TMessage>): () => void;
}

/**
 * Zustand slice for managing shared, real-time telemetry data from Skybus.
 *
 * This slice handles setting and clearing the transport, updating the latest
 * channel data, and subscribing to channels for specific devices.
 *
 * @warning **DO NOT** modify this store directly. Use the provided utilities and hooks instead.
 */
export const createDeviceChannelsSlice: SliceCreator<keyof DeviceChannelsSlice> = (set, _get) => {
  return {
    transport: new NoopTransport(),
    tunnels: {},
    channels: {},

    setTransport: transport =>
      set(state => {
        state.transport = transport;
      }),
    clearTransport: () =>
      set(state => {
        state.transport = new NoopTransport();
      }),
    setChannelLatest: (deviceId, channel, payload) =>
      set(state => {
        // TODO(trey): should be a rollup
        // console.debug("received message", { size: payload.length, channel, id });

        if (!state.channels[deviceId]?.index.hasOwnProperty(channel)) {
          console.debug("received message for unsubscribed channel", { channel });
          return;
        }

        try {
          const message = state.channels[deviceId].index[channel].channel.type(payload);
          state.channels[deviceId].latest[channel] = message;
        } catch (e) {
          console.error("error decoding message for channel", { channel, e });
          return;
        }
      }),
    addTunnel: (...tunnels) => {
      set(state => {
        tunnels.forEach(tunnel => {
          const dst = tunnel.getDst();
          console.debug(`Adding tunnel for deviceId: ${dst}`);
          state.tunnels[dst] = tunnel;
        });
      });

      return () =>
        set(state => {
          tunnels.forEach(tunnel => {
            const dst = tunnel.getDst();
            console.debug(`Removing tunnel for deviceId: ${dst}`);
            delete state.tunnels[dst];
          });
        });
    },
    subscribe: (deviceId, channel) => {
      set(state => {
        if (!state.channels.hasOwnProperty(deviceId)) {
          state.channels[deviceId] = { latest: {}, index: {} };
        }

        console.debug("subscribing to channel", { id: deviceId, channel: channel.channel });
        if (!state.channels[deviceId].index.hasOwnProperty(channel.channel)) {
          state.channels[deviceId].index[channel.channel] = { refCount: 0, channel };
        }
        state.channels[deviceId].index[channel.channel].refCount += 1;
      });

      return () =>
        set(state => {
          if (!state.channels.hasOwnProperty(deviceId)) {
            console.warn("tried to unsubscribe from channel for unknown device", {
              id: deviceId,
              channel: channel.channel,
            });
            return;
          }

          if (!state.channels[deviceId].index.hasOwnProperty(channel.channel)) {
            console.warn("tried to unsubscribe from already unsubscribed channel", {
              id: deviceId,
              channel: channel.channel,
            });
            return;
          }

          console.debug("unsubscribing from channel", { id: deviceId, channel: channel.channel });
          state.channels[deviceId].index[channel.channel].refCount -= 1;
        });
    },
  };
};
