"use client";

import { _roots, createRoot, extend, events as fiberEvents } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { useStore } from "zustand";

import { nomadContext, useNomadStore } from "./store";
import { CameraSync } from "./mapbox-camera-sync";
import { MapboxMap } from "./mapbox-map";
import { useFunction } from "./use-function";

import type { RenderProps } from "@react-three/fiber";
import type { Map } from "mapbox-gl-new";
import type { PropsWithChildren } from "react";
import type { PerspectiveCamera } from "three";
import type { Props as MapboxMapProps } from "./mapbox-map";

// Read here for why we do this: https://github.com/framer/motion/issues/2074#issuecomment-1724813108
// Also see https://docs.pmnd.rs/react-three-fiber/api/canvas#tree-shaking
extend(THREE);

export interface NomadMapProps extends CanvasProps {
  initialViewState: MapboxMapProps["initialViewState"];
  accessToken: MapboxMapProps["accessToken"];
}

export function NomadMap({
  children,
  accessToken,
  initialViewState,
  ...canvasProps
}: NomadMapProps) {
  const nomadStore = useNomadStore();
  const map = useStore(nomadStore, state => state.mapboxMap);
  const mapboxLoaded = useStore(nomadStore, state => state.mapboxLoaded);
  const mapStyle = useStore(nomadStore, state => state.mapStyle);
  const worldGroup = useStore(nomadStore, state => state.worldGroup);
  const setMapboxLoaded = useStore(nomadStore, state => state._setMapboxLoaded);
  const cleanup = useStore(nomadStore, state => state._cleanup);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <MapboxMap
        accessToken={accessToken}
        style={mapStyle}
        initialViewState={initialViewState}
        antialias={true}
        onLoad={e => {
          setMapboxLoaded(true);
        }}
        dragPan={{
          // TODO: Figure out how to support easing.
          // We don't support it yet because if we do, the ThreeJS content
          // swims around when easing.
          easing: v => 0,
        }}
      />

      {map && mapboxLoaded && (
        <R3FCanvas map={map} {...canvasProps}>
          <nomadContext.Provider value={nomadStore}>
            <primitive object={worldGroup}>{children}</primitive>
          </nomadContext.Provider>
        </R3FCanvas>
      )}
    </div>
  );
}

interface CanvasProps
  extends Omit<RenderProps<HTMLCanvasElement>, "frameloop" | "camera">,
    PropsWithChildren {}

interface CanvasPropsWithMap extends CanvasProps {
  map: Map;
}
/**
 * Ported from react-three-map.
 * @see https://github.com/RodrigoHamuy/react-three-map/blob/4d005cd1ab04e3549611d16aea9958a534838a8a/src/core/canvas-in-layer/use-canvas-in-layer.tsx#L8
 */
function useCanvasInLayer({ children, ...props }: CanvasProps, map: Map, worldGroup: THREE.Group) {
  const nomadStore = useNomadStore();
  const setCameraSync = useStore(nomadStore, state => state._setCameraSync);
  const setR3FState = useStore(nomadStore, state => state._setR3Fstate);

  const rootRef = useRef<ReturnType<typeof createRoot> | null>(null);

  const { root, store } = useMemo(() => {
    const canvas = map.getCanvas();
    const gl = canvas.getContext("webgl2") as WebGL2RenderingContext;

    if (!rootRef.current || !_roots.get(canvas)) {
      rootRef.current = createRoot(canvas);
    }

    const root = rootRef.current;
    root.configure({
      dpr: window.devicePixelRatio,
      events: fiberEvents,
      ...props,
      frameloop: "never",
      gl: {
        context: gl,
        autoClear: false,
        antialias: true,
        ...props?.gl,
      },
      onCreated: state => {
        state.gl.forceContextLoss = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
        props.onCreated?.(state);
      },
      camera: {
        matrixAutoUpdate: false,
        near: 1,
        far: 10000,
      },
      size: {
        width: canvas.clientWidth,
        height: canvas.clientHeight,
        top: canvas.offsetTop,
        left: canvas.offsetLeft,
        updateStyle: false,
        ...props?.size,
      },
    });

    const store = _roots.get(canvas)!.store; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return { root, store };
  }, [map, worldGroup, props]);

  const cameraSync = useMemo(() => {
    const camera = store.getState().camera as PerspectiveCamera;
    return new CameraSync(map, camera, worldGroup);
  }, [map, store, worldGroup]);

  const addCustomLayer = (cameraSync: CameraSync) => {
    if (!map.style.getOwnLayer("mapboxSyncLayer")) {
      map.addLayer({
        id: "mapboxSyncLayer",
        type: "custom",
        renderingMode: "3d",
        render: () => {
          const canvas = map.getCanvas();
          const store = _roots.get(canvas)!.store; // eslint-disable-line @typescript-eslint/no-non-null-assertion
          const state = store.getState();
          const { gl, advance } = state;

          gl.resetState();

          advance(Date.now() * 0.001, true);

          map.triggerRepaint();
        },
        prerender: () => {
          cameraSync.update();
        },
      });
    }
  };

  useEffect(() => {
    const loadEvent = () => {
      addCustomLayer(cameraSync);
    };

    const mapboxSyncLayer = map.style.getOwnLayer("mapboxSyncLayer");
    if (!mapboxSyncLayer) {
      const styleLoaded = map.style.loaded();
      if (styleLoaded) {
        addCustomLayer(cameraSync);
      } else {
        map.once("load", loadEvent);
      }
    }

    // Add event listener for style.load
    map.on("style.load", loadEvent);

    return () => {
      // Remove the event listeners
      map.off("load", loadEvent);
      map.off("style.load", loadEvent);

      // Remove the layer if it exists
      if (map && map.style && map.style.getOwnLayer("mapboxSyncLayer")) {
        map.removeLayer("mapboxSyncLayer");
      }
    };
  }, [map, cameraSync]);

  const onResize = useFunction(() => {
    const canvas = map.getCanvas();
    const store = _roots.get(canvas)?.store; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    if (!store) return;
    const { setDpr, setSize } = store.getState();

    setDpr(window.devicePixelRatio);
    setSize(canvas.clientWidth, canvas.clientHeight, false, canvas.offsetTop, canvas.offsetLeft);
  });

  // on mount / unmount
  useEffect(() => {
    map.on("resize", onResize);
    return () => {
      map.off("resize", onResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setR3FState(store);
    return () => {
      setR3FState(null);
    };
  }, [setR3FState, store]);

  useEffect(() => {
    const state = store.getState();
    const cameraSync = new CameraSync(map, state.camera as PerspectiveCamera, worldGroup);
    setCameraSync(cameraSync);

    return () => {
      setCameraSync(null);
    };
  }, [map, worldGroup, store, setCameraSync]);

  useEffect(() => {
    root.render(<>{children}</>);
  }, [children]); // eslint-disable-line react-hooks/exhaustive-deps
}

function R3FCanvas({ map, ...props }: CanvasPropsWithMap) {
  const nomadStore = useNomadStore();
  const worldGroup = useStore(nomadStore, state => state.worldGroup);

  useCanvasInLayer(props, map, worldGroup);

  return null;
}
