"use client";

import { createContext, useContext, useRef } from "react";
import { Group } from "three";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { _roots } from "@react-three/fiber";

import type { Map as MapboxGLMap } from "mapbox-gl-new";
import type { PropsWithChildren, MutableRefObject } from "react";
import type { PerspectiveCamera } from "three";
import type { CameraSync } from "./mapbox-camera-sync";
import type { Props as MapboxMapProps } from "./mapbox-map";

export type ProjectionMode = "perspective" | "orthographic";

/**
 * Camera properties for each projection mode.
 *
 * It store per-projection mode properties, so that these can be switched when the projection mode
 * is changed.
 */
interface CameraProperties {
  /**
   * Vertical FOV, in degrees.
   */
  fov: number;
  pitch: number;
  canRotate: boolean;
}

const CameraPropertiesToProjectionmode: Record<ProjectionMode, CameraProperties> = {
  perspective: {
    /**
     * Vertical FOV, in degrees.
     */
    fov: 50,
    pitch: 45,
    canRotate: true,
  },
  orthographic: {
    /**
     * Vertical FOV, in degrees.
     *
     * A value that looks good, arrived at empirically.
     * This is as small as we can make it while still preventing mapbox
     * from flickering.
     */
    fov: 2.2,
    pitch: 0,
    canRotate: false,
  },
};

interface NomadStoreProps {
  mapStyle?: MapboxMapProps["style"];
  projectionMode?: ProjectionMode;
  htmlDivRef?: MutableRefObject<HTMLDivElement | null>;
}

export interface NomadState {
  mapboxMap: MapboxGLMap | null;
  setMapboxMap: (map: MapboxGLMap | null) => void;

  mapStyle: MapboxMapProps["style"];
  setMapStyle: (style: MapboxMapProps["style"]) => void;

  threeStateRef: NonNullable<ReturnType<typeof _roots.get>>["store"] | null;
  /**
   * @warning This is a private method, do not call it directly.
   */
  _setR3Fstate: (r3fState: NonNullable<ReturnType<typeof _roots.get>>["store"] | null) => void;

  /**
   * A reference to the div that can be used to render HTML elements on top of the map, needs to be passed to `Html` component as `portal` prop
   */
  htmlDivRef: MutableRefObject<HTMLDivElement | null> | null;
  setHtmlDivRef: (ref: MutableRefObject<HTMLDivElement | null> | null) => void;

  mapboxLoaded: boolean;
  /**
   * @warning This is a private method, do not call it directly.
   */
  _setMapboxLoaded: (loaded: boolean) => void;

  worldGroup: Group;
  /**
   * @warning This is a private method, do not call it directly.
   */
  _setWorldGroup: (group: Group) => void;

  projectionMode: ProjectionMode;
  setProjectionMode: (mode: ProjectionMode) => void;

  cameraSync: CameraSync | null;
  /**
   * @warning This is a private method, do not call it directly.
   */
  _setCameraSync: (cameraSync: CameraSync | null) => void;

  /**
   * A task that runs once the whole setup is complete.
   * This means after Mapbox and ThreeJS are both loaded.
   *
   * Useful to run tasks that depend on both Mapbox and ThreeJS being loaded.
   *
   * @warning This is a private field, do not use it directly.
   */
  _postSetupTask: (() => void) | null;

  _cleanup: () => void;

  enableMapboxCameraMovement: (enable: boolean) => void;
}

/**
 * @see
 * https://docs.pmnd.rs/zustand/guides/initialize-state-with-props#store-creator-with-createstore
 * @see
 * https://github.com/pmndrs/react-three-fiber/blob/01fe9b147685593f42613c67e7d4aedf99456431/packages/fiber/src/core/store.ts#L156
 * @see
 * https://github.com/pmndrs/zustand/blob/9857a676a94c81ec561f99a13d46d6f6f3376f84/readme.md#using-subscribe-with-selector
 */
export const createNomadStore = (initialProps: NomadStoreProps) =>
  create<NomadState>()(
    subscribeWithSelector((set, get) => {
      function createInitialState() {
        const worldGroup = new Group();
        worldGroup.name = "World Group";
        worldGroup.matrixAutoUpdate = false;

        return {
          mapboxMap: null,
          threeStateRef: null,
          mapboxLoaded: false,
          worldGroup,
          cameraSync: null,
          projectionMode: "perspective" as ProjectionMode,
          mapStyle: initialProps.mapStyle ?? "mapbox://styles/mapbox/light-v10",
          htmlDivRef: initialProps.htmlDivRef ?? null,
        };
      }

      return {
        ...createInitialState(),
        setMapboxMap: map => set({ mapboxMap: map }),
        setMapStyle: style => set({ mapStyle: style }),
        setHtmlDivRef: ref => set({ htmlDivRef: ref }),
        _setR3Fstate: r3fState => {
          set({ threeStateRef: r3fState });
          const postSetupTask = get()._postSetupTask;
          postSetupTask?.();
        },
        _setMapboxLoaded: loaded => {
          set({ mapboxLoaded: loaded });
          const postSetupTask = get()._postSetupTask;
          postSetupTask?.();
        },
        _setWorldGroup: group => set({ worldGroup: group }),
        setProjectionMode: mode => {
          const map = get().mapboxMap;
          if (!map) {
            console.warn("Map not loaded, cannot change projection mode");
            return;
          }

          const threeState = get().threeStateRef?.getState();
          if (!threeState) {
            console.warn("Three state not loaded, cannot change projection mode");
            return;
          }

          // @ts-ignore
          map.transform.fov = CameraPropertiesToProjectionmode[mode].fov;
          const camera = threeState.camera as PerspectiveCamera;
          camera.fov = CameraPropertiesToProjectionmode[mode].fov;

          if (CameraPropertiesToProjectionmode[mode].canRotate) {
            // This is Mapbox's default
            map.setMaxPitch(85);
            map.touchPitch.enable();
          } else {
            map.setMaxPitch(0);
            map.touchPitch.disable();
          }
          map.setPitch(CameraPropertiesToProjectionmode[mode].pitch);

          map.repaint = true;
          // @ts-ignore
          map.transform._updateCameraOnTerrain();

          set({ projectionMode: mode });
        },
        _setCameraSync: cameraSync => set({ cameraSync }),
        _postSetupTask: () => {
          const map = get().mapboxMap;
          const threeState = get().threeStateRef;
          if (!map || !threeState) {
            return;
          }

          // This lets us set the projection mode via a prop
          if (initialProps.projectionMode) {
            get().setProjectionMode(initialProps.projectionMode);
          }

          set({ _postSetupTask: null });
        },
        // Add the cleanup method
        _cleanup: () => {
          // Reset the store to its initial state
          set(createInitialState());
        },
        enableMapboxCameraMovement: enable => {
          const map = get().mapboxMap;
          if (!map) {
            console.warn("Map not loaded, cannot enable/disable camera movement");
            return;
          }

          const projectionMode = get().projectionMode;

          if (enable) {
            map.dragPan.enable();
            map.dragRotate.enable();
            map.touchZoomRotate.enable();
            if (CameraPropertiesToProjectionmode[projectionMode].canRotate) {
              // This is Mapbox's default
              map.setMaxPitch(85);
              map.touchPitch.enable();
            } else {
              map.setMaxPitch(0);
              map.touchPitch.disable();
            }
          } else {
            map.dragPan.disable();
            map.dragRotate.disable();
            map.touchZoomRotate.disable();
            map.touchPitch.disable();
          }
        },
      };
    })
  );

/**
 * @see
 * https://docs.pmnd.rs/zustand/guides/initialize-state-with-props#store-creator-with-createstore
 */
export type NomadStore = ReturnType<typeof createNomadStore>;

/**
 * @see
 * https://docs.pmnd.rs/zustand/guides/initialize-state-with-props#creating-a-context-with-react.createcontext
 */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const nomadContext = createContext<NomadStore>(null!);

export const useNomadStore = () => {
  return useContext(nomadContext);
};

/**
 * @see
 * https://docs.pmnd.rs/zustand/guides/initialize-state-with-props#wrapping-the-context-provider
 */
interface NomadStoreProviderProps extends PropsWithChildren<unknown> {
  initialStoreProps: NomadStoreProps;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __DEV_GET_ZUSTAND_STORE_MAP: any;
  }
}

/**
 * @see
 * https://docs.pmnd.rs/zustand/guides/initialize-state-with-props#wrapping-the-context-provider
 */
export function NomadStoreProvider({ children, initialStoreProps }: NomadStoreProviderProps) {
  const storeRef = useRef<NomadStore>();
  if (!storeRef.current) {
    const store = createNomadStore(initialStoreProps);
    storeRef.current = store;
    // Expose Zustand store to global window, for Playwright testing purposes
    if (typeof window !== "undefined") {
      window.__DEV_GET_ZUSTAND_STORE_MAP = () => store;
    }
  }
  return <nomadContext.Provider value={storeRef.current}>{children}</nomadContext.Provider>;
}
