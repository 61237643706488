"use client";

import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";
import { Vector3 } from "three";
import { useStore } from "zustand";

import { useNomadStore } from "./store";

import type { GroupProps } from "@react-three/fiber";
import type { Group } from "three";

/**
 * Utility Entity which shows a zoom-invariant group.
 */
export function ZoomInvariantGroup({ children, ...rest }: GroupProps) {
  const ref = useRef<Group>(null);

  const nomadStore = useNomadStore();
  const worldGroup = useStore(nomadStore, state => state.worldGroup);

  const [cv] = useState(() => new Vector3());

  useFrame(() => {
    const group = ref.current;
    if (!group) return;

    // Scale the sprite based on the inverse of the scale of the worldgroup
    // Read the scale from the world group's matrix
    const worldGroupMatrix = worldGroup.matrix;

    // Ported from https://github.com/mrdoob/three.js/blob/bc62d42c4187dfa2c64fee29aaf247e3d5d5d850/src/math/Matrix4.js#L732
    const e = worldGroupMatrix.elements;

    // Only read the X scale, as all the others will also be scaled the same amount
    let sx = cv.set(e[0], e[1], e[2]).length();

    // if determine is negative, we need to invert one scale
    const det = worldGroupMatrix.determinant();
    if (det < 0) sx = -sx;

    const scale = 1 / sx;
    group.scale.setScalar(scale);
  });

  return (
    <group ref={ref} {...rest}>
      {children}
    </group>
  );
}
