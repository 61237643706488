import { ADSB_IN_PB } from "@skydio/channels/src/adsb_in_pb";
import { AMBASSADOR_VEHICLE_STATUS_PB } from "@skydio/channels/src/ambassador_vehicle_status_pb";
import { MISSION_STATE_PB } from "@skydio/channels/src/mission_state_pb";
import { SERVER_INFO_PB } from "@skydio/channels/src/server_info_pb";
import { USER_PROMPTS_PB } from "@skydio/channels/src/user_prompts_pb";
import { VEHICLE_AND_CAMERA_POSE_LITE_PB } from "@skydio/channels/src/vehicle_and_camera_pose_lite_pb";
import { ClientType } from "@skydio/pbtypes/pbtypes/gen/cloud_config/client_type_pb";
import { PilotRequest } from "@skydio/pbtypes/pbtypes/vehicle/pilot_service/pilot_pb";
import { utimeNow } from "@skydio/skybus-experimental";
import { ProtocolChannel } from "@skydio/skybus/src/transport/livekit/livekit_transport_layer";

import { NoopTransport } from "../deviceChannels/deviceChannelsSlice";
import sendMessageOverTransport from "./utils/sendMessageOverTransport";

import type { Channel } from "@skydio/channels";
import type { SliceCreator } from "../zustandTypes";

export interface VehicleDataSlice {
  vehicleMessages: { [vehicleId: string]: any };
  onlineVehicles: string[]; // List of manually set online vehicles
  setOnlineVehicles: (vehicleIds: string[]) => void; // Method to set online vehicles
  userUuid: string | null; // UUID of the current user
  setUserUuid: (uuid: string) => void; // Method to set the user UUID
  /** Method to request pilotship for a vehicle given a client ID */
  requestPilotForVehicle: (
    vehicleId: string,
    clientId: string,
    retriesLeft?: number,
    retryIntervalMs?: number
  ) => Promise<boolean>;
}

export const createVehicleDataSlice: SliceCreator<keyof VehicleDataSlice> = (set, get, store) => {
  const vehicleChannels: Channel<any>[] = [
    VEHICLE_AND_CAMERA_POSE_LITE_PB,
    AMBASSADOR_VEHICLE_STATUS_PB,
    SERVER_INFO_PB,
    ADSB_IN_PB,
    MISSION_STATE_PB,
    USER_PROMPTS_PB,
    // Add other channels here
  ];

  const setUserUuid = (uuid: string) => {
    set(state => {
      state.userUuid = uuid;
    });
  };

  const subscribeToVehicleChannels = (vehicleId: string) => {
    vehicleChannels.forEach(channel => {
      store.getState().subscribe(vehicleId, channel);
    });
  };

  const unsubscribeFromVehicleChannels = (vehicleId: string) => {
    vehicleChannels.forEach(channel => {
      const unsubscribe = store.getState().subscribe(vehicleId, channel);
      unsubscribe(); // Immediately unsubscribe to remove the listener
    });
  };

  /**
   * Set the list of online vehicles. This determines which vehicles we subscribe to channels for,
   * as well as open tunnel connections programmatically.
   */
  const setOnlineVehicles = (vehicleIds: string[]) => {
    const currentOnlineVehicles = get().onlineVehicles;

    // Determine which vehicles are new and which have gone offline
    const newOnlineVehicles = vehicleIds.filter(id => !currentOnlineVehicles.includes(id));
    const offlineVehicles = currentOnlineVehicles.filter(id => !vehicleIds.includes(id));

    // Subscribe to channels for new online vehicles
    newOnlineVehicles.forEach(vehicleId => {
      subscribeToVehicleChannels(vehicleId);
    });

    // Unsubscribe from channels for vehicles that are no longer online
    offlineVehicles.forEach(vehicleId => {
      unsubscribeFromVehicleChannels(vehicleId);
    });

    // Update the list of online vehicles in the state
    set(state => {
      state.onlineVehicles = vehicleIds;
    });

    // No need to update vehicleMessages here; this will be handled by the subscription below.
  };

  const requestPilotForVehicle = async (
    vehicleId: string,
    clientId: string,
    retriesLeft = 5, // Default retries
    retryIntervalMs = 1000 // Default interval in milliseconds
  ): Promise<boolean> => {
    const transport = get().transport;

    if (transport instanceof NoopTransport) {
      console.warn("Not sending pilot request because transport is not available");
      return false; // Return false if transport is not available
    }

    const userUuid = get().userUuid;

    const sendPilotRequest = async (retriesLeft: number): Promise<boolean> => {
      const currentPilotId =
        get().channels[vehicleId]?.latest[SERVER_INFO_PB.channel]?.getPilotId();

      // We immediately return true if the user is already the pilot
      if (currentPilotId === userUuid) {
        console.warn("Not sending pilot request because user is already the pilot");
        return true;
      }

      // Prepare and send the pilot request
      const pilotRequest = new PilotRequest();
      pilotRequest.setUtime(utimeNow());
      pilotRequest.setAction(PilotRequest.Action.COMMANDEER);
      pilotRequest.setClientId(clientId);
      pilotRequest.setClientType(ClientType.Enum.WEB);

      try {
        await sendMessageOverTransport(
          vehicleId,
          ProtocolChannel.PILOT_REQUEST,
          pilotRequest.serializeBinary(),
          transport
        );

        // Wait for the specified delay before checking if pilotship was acquired
        await new Promise(resolve => setTimeout(resolve, retryIntervalMs));

        // Recheck the pilot ID to confirm we became the pilot
        const updatedPilotId =
          get().channels[vehicleId]?.latest[SERVER_INFO_PB.channel]?.getPilotId();

        // We confirm that user's clientId matches what vehicle reports as the pilot ID
        if (updatedPilotId === clientId) {
          return true;
        } else {
          throw new Error("Pilotship request sent but did not acquire pilotship");
        }
      } catch (error) {
        if (retriesLeft > 0) {
          console.warn(
            `Failed to acquire pilot. Retrying in ${retryIntervalMs / 1000} seconds... (${retriesLeft} retries left)`
          );
          return sendPilotRequest(retriesLeft - 1);
        } else {
          console.error("Failed to acquire pilotship after maximum retries.");
          return false;
        }
      }
    };

    return sendPilotRequest(retriesLeft);
  };

  return {
    vehicleMessages: {},
    onlineVehicles: [],
    setOnlineVehicles,
    userUuid: null,
    setUserUuid,
    requestPilotForVehicle,
  };
};
